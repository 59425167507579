<template>
  <div class="page">
    <iframe
      id="mapPage"
      width="100%"
      height="100%"
      frameborder="0"
      src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=RXSBZ-LRKK3-TPO32-RYEX5-IORC3-FPBMD&referer=myapp"
    >
    </iframe>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  props: {
  },
  mounted () {
    window.addEventListener('message', function (event) {
      // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
      var loc = event.data
      if (loc && loc.module === 'locationPicker') {
        // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
        console.log('location', loc)
      }
    }, false)
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.page {
  height: 100vh;
}
</style>
